import { ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const Wrapper = styled.div`
  padding: 80px 0;

  ${media.lg.min} {
    padding: 110px 0 100px;
  }
`

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }

  p:first-of-type {
    font-family: Merriweather;
  }
`

const TilesWrapper = styled.div`
  display: grid;
  gap: 20px;

  ${media.lg.min} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const SingleTile = styled.div`
  position: relative;
  height: 300px;

  ${media.lg.min} {
    height: 400px;

    grid-column: span 2;

    &:nth-child(4) {
      grid-column: 1/7;
    }

    &:nth-child(5),
    &:nth-child(6) {
      grid-column: span 3;
    }
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const Label = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  font-family: Merriweather;

  ${media.lg.min} {
    bottom: 40px;
    left: 30px;
    transform: translateX(0);
  }
`

type Props = {
  heading: string
  tiles: Array<{
    image: {
      src: ImageDataLike
      alt: string
    }
    label: string
  }>
}

const Inspirations: React.FC<Props> = ({ heading, tiles }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <StyledContainer>
        <Heading
          as="p"
          size={lg ? 38 : 22}
          margin={lg ? '54px' : '40px'}
          sizeDiff={0}
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <TilesWrapper>
          {tiles?.map(({ image, label }) => (
            <SingleTile>
              <Background>
                <LazyImage src={image?.src} alt={image?.alt} fill />
              </Background>
              <Label dangerouslySetInnerHTML={{ __html: label }} />
            </SingleTile>
          ))}
        </TilesWrapper>
      </StyledContainer>
    </Wrapper>
  )
}

export default Inspirations
