import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  width: 77px;
  margin-right: 36px;

  ${media.lg.min} {
    width: 100px;
    margin-right: 11px;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  width: 77px;
  height: 108px;

  margin-bottom: 5px;

  p {
    position: relative;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 4px;
    border-radius: 16px;
  }

  ${media.lg.min} {
    width: 100px;
    height: 140px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

type Props = {
  code: string
  name: string
  image: {
    src: ImageDataLike
    alt: string
  }
}

const Component: React.FC<Props> = ({ code, name, image }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Background>
          <LazyImage src={image?.src} alt={image?.alt} fill />
        </Background>
        <Text size={14} margin="5px">
          {code}
        </Text>
      </InnerWrapper>
      <Text size={14} themecolor="white" align="center">
        {name}
      </Text>
    </Wrapper>
  )
}

export default Component
