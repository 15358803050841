import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import Button from 'components/shared/button'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  position: relative;
  margin-top: 60px;
  padding: 45px 0;

  p:first-of-type {
    font-family: Merriweather;
  }

  ${media.lg.min} {
    margin-top: 90px;
    padding: 65px 0 60px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const StagesWrapper = styled.div`
  padding: 26px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.lg.min} {
    padding: 60px 0 100px 0;
    flex-direction: row;
  }
`

const StageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lg.min} {
    flex-direction: row;
  }
`

const StageItemWrapper = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.midnightgreen};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  ${media.lg.min} {
    width: 160px;
    height: 160px;
  }
`

const Line = styled.div`
  position: relative;
  height: 43px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.midnightgreen};

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.midnightgreen};
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  ${media.lg.min} {
    width: 100px;
    height: 1px;

    &:before {
      width: 14px;
      height: 14px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a:first-child {
    margin-bottom: 11px;
  }

  ${media.lg.min} {
    flex-direction: row;
    justify-content: center;

    a:first-child {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
`

type Props = {
  image: {
    src: ImageDataLike
    alt: string
  }
  heading: string
  text: string
  stages: string[]
  link: string
}

const Stages: React.FC<Props> = ({ image, heading, text, stages, link }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Background>
        <LazyImage src={image?.src} alt={image?.alt} fill />
      </Background>
      <Container>
        <Heading
          as="p"
          size={lg ? 38 : 26}
          sizeDiff={0}
          themecolor="white"
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text
          size={lg ? 20 : 16}
          themecolor="white"
          align="center"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <StagesWrapper>
          {stages?.map((stage, index) => {
            const isLast = index === stages.length - 1
            return (
              <StageWrapper key={stage}>
                <StageItemWrapper>
                  <Text
                    size={lg ? 22 : 15}
                    themecolor="white"
                    align="center"
                    margin="0"
                    dangerouslySetInnerHTML={{ __html: stage }}
                  />
                </StageItemWrapper>
                {!isLast && <Line />}
              </StageWrapper>
            )
          })}
        </StagesWrapper>
        <ButtonsWrapper>
          <Button as={Link} to="/kontakt/">
            Skontaktuj się z nami
          </Button>
          <Button as="a" href={link}>
            Pobierz instrukcję
          </Button>
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  )
}

export default Stages
