import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import ColorTile from 'components/pages/sliding-doors/colors/color-tile'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-bold.svg'

import type { Color } from 'components/pages/sliding-doors/colors'

const ColorsWrapper = styled.div`
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.pewterblue};
  padding: 30px 0;

  ${media.lg.min} {
    padding: 40px 0;
  }
`

const ColorsContainer = styled(Container)<{ expanded: boolean }>`
  height: 234px;
  overflow: hidden;

  ${media.lg.min} {
    height: 266px;
    padding: 0 55px;
    display: flex;
    column-gap: 60px;
    flex-wrap: wrap;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      height: auto;
      ${media.lg.min} {
        height: auto;
      }
    `}
`

const StyledHeading = styled(Heading)`
  position: relative;
  font-family: Merriweather;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100px;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${media.lg.min} {
    &:before,
    &:after {
      width: 350px;
    }
  }
`

const CategoryWrapper = styled.div`
  margin-top: 25px;
`

const ColorsInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Button = styled.button`
  margin: 0 auto;
  padding-top: 30px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.div<{ expanded: boolean }>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
  transition: 0.3s;
  transform: rotateX(180deg);

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotateX(0);
    `};
`

type Props = {
  heading: string
  colors: Color[]
}

const ColorPalette: React.FC<Props> = ({ heading, colors }) => {
  const { lg } = useBreakpoint()

  const [expanded, setExpanded] = useState(false)
  const [expandButtonVisible, setExpandButtonVisible] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setExpandButtonVisible(
      ref.current!.scrollHeight > ref.current!.clientHeight + 200
    )
  }, [])

  return (
    <ColorsWrapper>
      <StyledHeading
        as="p"
        size={lg ? 30 : 24}
        themecolor="white"
        align="center"
        sizeDiff={0}
      >
        {heading}
      </StyledHeading>
      <ColorsContainer expanded={expanded} ref={ref}>
        {colors?.map(({ categoryName, colors: colorItems }) => (
          <CategoryWrapper>
            <Text size={18} themecolor="white" weight={600}>
              {categoryName}
            </Text>
            <ColorsInnerWrapper>
              {colorItems?.map(({ code, name, image }) => (
                <ColorTile code={code} name={name} image={image} />
              ))}
            </ColorsInnerWrapper>
          </CategoryWrapper>
        ))}
      </ColorsContainer>

      {expandButtonVisible && (
        <Button onClick={() => setExpanded(!expanded)}>
          <Arrow expanded={expanded}>
            <Icon src={arrowIcon} height={22} />
          </Arrow>
          <Text size={14} themecolor="white" margin="0">
            {expanded ? 'Zwiń' : 'Rozwiń'}
          </Text>
        </Button>
      )}
    </ColorsWrapper>
  )
}

export default ColorPalette
