import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'

import ContactSectionDoor from 'components/contact-section-door'

import SlidingDoors from 'components/pages/sliding-doors/sliding-doors'
import Colors from 'components/pages/sliding-doors/colors'
import Accessories from 'components/pages/sliding-doors/accessories'
import Stages from 'components/pages/sliding-doors/stages'
import Inspirations from 'components/pages/sliding-doors/inspirations'

import { SlidingDoorsPageQuery } from 'types/graphql'

const DrzwiPrzesuwne: React.FC<PageProps<SlidingDoorsPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.slidingDoorsPage!
  const PAGE_SEO = data?.wpPage?.seo!

  const SLIDING_DOORS_TILES =
    data?.allWpDoorsSingle?.nodes?.map((door) => ({
      title: door?.title!,
      slug: door?.slug!,
      image: {
        src: door?.featuredImage?.node?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: door?.featuredImage?.node?.altText!,
      },
    })) || []

  const COLORS =
    PAGE?.slidingDoorsPageS2Colors?.map((cat) => ({
      categoryName: cat?.slidingDoorsPageS2ColorsCategory!,
      colors:
        cat?.slidingDoorsPageS2ColorsCategoryColors?.map((col) => ({
          code: col?.slidingDoorsPageS2ColorsCategoryColorsColor
            ?.slidingDoorsPageS2ColorsCategoryColorsColorCode!,
          name: col?.slidingDoorsPageS2ColorsCategoryColorsColor
            ?.slidingDoorsPageS2ColorsCategoryColorsColorName!,
          image: {
            src: col?.slidingDoorsPageS2ColorsCategoryColorsColor
              ?.slidingDoorsPageS2ColorsCategoryColorsColorImage?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: col?.slidingDoorsPageS2ColorsCategoryColorsColor
              ?.slidingDoorsPageS2ColorsCategoryColorsColorImage?.altText!,
          },
        })) || [],
    })) || []

  const FILLINGS =
    PAGE?.slidingDoorsPageS2Fillings?.map((cat) => ({
      categoryName: cat?.slidingDoorsPageS2FillingsCategory!,
      colors:
        cat?.slidingDoorsPageS2FillingsCategoryFillings?.map((col) => ({
          code: col?.slidingDoorsPageS2FillingsCategoryFillingsFilling
            ?.slidingDoorsPageS2FillingsCategoryFillingsFillingCode!,
          name: col?.slidingDoorsPageS2FillingsCategoryFillingsFilling
            ?.slidingDoorsPageS2FillingsCategoryFillingsFillingName!,
          image: {
            src: col?.slidingDoorsPageS2FillingsCategoryFillingsFilling
              ?.slidingDoorsPageS2FillingsCategoryFillingsFillingImage
              ?.localFile?.childImageSharp?.gatsbyImageData!,
            alt: col?.slidingDoorsPageS2FillingsCategoryFillingsFilling
              ?.slidingDoorsPageS2FillingsCategoryFillingsFillingImage
              ?.altText!,
          },
        })) || [],
    })) || []

  const STAGES =
    PAGE?.slidingDoorsPageS4Stages?.map(
      (stage) => stage?.slidingDoorsPageS4StagesStage!
    ) || []

  const INSPIRATIONS =
    PAGE?.slidingDoorsPageS5Tiles?.map((tile) => ({
      image: {
        src: tile?.slidingDoorsPageS5TilesTile?.slidingDoorsPageS5TilesTileImage
          ?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: tile?.slidingDoorsPageS5TilesTile?.slidingDoorsPageS5TilesTileImage
          ?.altText!,
      },
      label:
        tile?.slidingDoorsPageS5TilesTile?.slidingDoorsPageS5TilesTileLabel!,
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs spacing crumbs={[{ name: 'Drzwi przesuwne' }]} />
      <SlidingDoors
        heading={PAGE?.slidingDoorsPageS1Title!}
        text={PAGE?.slidingDoorsPageS1Paragraph!}
        tiles={SLIDING_DOORS_TILES}
      />
      <Colors
        heading={PAGE?.slidingDoorsPageS2Heading!}
        annotations={PAGE?.slidingDoorsPageS2Annotations!}
        colors={COLORS}
        fillings={FILLINGS}
      />
      <Accessories
        heading={PAGE?.slidingDoorsPageS3Heading!}
        text={PAGE?.slidingDoorsPageS3Paragraph!}
      />
      <Stages
        image={{
          src: PAGE?.slidingDoorsPageS4Image?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.slidingDoorsPageS4Image?.altText!,
        }}
        heading={PAGE?.slidingDoorsPageS4Heading!}
        text={PAGE?.slidingDoorsPageS4Paragraph!}
        stages={STAGES}
        link={PAGE?.slidingDoorsPageS4InstructionLink!}
      />
      <Inspirations
        heading={PAGE?.slidingDoorsPageS5Heading!}
        tiles={INSPIRATIONS}
      />
      <ContactSectionDoor
        title={PAGE?.slidingDoorsPageS6Heading!}
        text={PAGE?.slidingDoorsPageS6Paragraph!}
        phone={PAGE?.slidingDoorsPageS6Phone!}
        email={PAGE?.slidingDoorsPageS6Email!}
        initialFormMessage={PAGE?.slidingDoorsPageS6InitialFormMessage!}
      />
    </Layout>
  )
}

export default DrzwiPrzesuwne

export const query = graphql`
  query SlidingDoorsPage {
    wpPage(slug: { regex: "/drzwi-przesuwne/" }) {
      seo {
        ...WpSEO
      }
      slidingDoorsPage {
        slidingDoorsPageS1Title
        slidingDoorsPageS1Paragraph
        slidingDoorsPageS2Heading
        slidingDoorsPageS2Colors {
          slidingDoorsPageS2ColorsCategory
          slidingDoorsPageS2ColorsCategoryColors {
            slidingDoorsPageS2ColorsCategoryColorsColor {
              slidingDoorsPageS2ColorsCategoryColorsColorCode
              slidingDoorsPageS2ColorsCategoryColorsColorName
              slidingDoorsPageS2ColorsCategoryColorsColorImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      height: 140
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
          }
        }
        slidingDoorsPageS2Fillings {
          slidingDoorsPageS2FillingsCategory
          slidingDoorsPageS2FillingsCategoryFillings {
            slidingDoorsPageS2FillingsCategoryFillingsFilling {
              slidingDoorsPageS2FillingsCategoryFillingsFillingCode
              slidingDoorsPageS2FillingsCategoryFillingsFillingName
              slidingDoorsPageS2FillingsCategoryFillingsFillingImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      height: 140
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
          }
        }
        slidingDoorsPageS2Annotations
        slidingDoorsPageS3Heading
        slidingDoorsPageS3Paragraph
        slidingDoorsPageS4Heading
        slidingDoorsPageS4Paragraph
        slidingDoorsPageS4Stages {
          slidingDoorsPageS4StagesStage
        }
        slidingDoorsPageS4InstructionLink
        slidingDoorsPageS4Image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        slidingDoorsPageS5Heading
        slidingDoorsPageS5Tiles {
          slidingDoorsPageS5TilesTile {
            slidingDoorsPageS5TilesTileLabel
            slidingDoorsPageS5TilesTileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
        slidingDoorsPageS6Heading
        slidingDoorsPageS6Paragraph
        slidingDoorsPageS6Email
        slidingDoorsPageS6Phone
        slidingDoorsPageS6InitialFormMessage
      }
    }
    allWpDoorsSingle {
      nodes {
        title
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
    }
  }
`
