import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'

import ColorPalette from 'components/pages/sliding-doors/colors/color-palette'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  padding: 75px 0 60px;

  ${media.lg.min} {
    padding: 110px 0 80px 0;
  }
`

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const StyledHeading = styled(Heading)`
  padding: 0 36px;
`

const Annotations = styled(Text)`
  ${media.lg.max} {
    padding: 0 36px;
  }
`

export type Color = {
  categoryName: string
  colors: Array<{
    code: string
    name: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

type Props = {
  heading: string
  annotations: string
  colors: Color[]
  fillings: Color[]
}

const Colors: React.FC<Props> = ({
  heading,
  annotations,
  colors,
  fillings,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <StyledHeading
        as="h2"
        size={lg ? 38 : 24}
        dangerouslySetInnerHTML={{ __html: heading }}
        align={lg ? 'center' : 'left'}
        margin={lg ? '40px' : '36px'}
        sizeDiff={0}
      />

      <StyledContainer>
        <ColorPalette heading="Kolory" colors={colors} />
        <ColorPalette heading="Wypełnienia" colors={fillings} />

        <Annotations dangerouslySetInnerHTML={{ __html: annotations }} />
      </StyledContainer>
    </Wrapper>
  )
}

export default Colors
